<template>
    <div class="indexbox">
        <img class="boximg" src="../assets/homeindex.png" alt="" srcset="">
        <indexnav name="企业简介" capslk="Q" english="Company Profile" />
        <div :class="flexshow?'companysmall':'company'">
            <div style="width:53%">
                <div class="companytext"> <span style="font-size:30px">标榜半岛教育集团</span>，总部位于深圳,深耕教育二十余年，现已成为<span>早期教育、学前教育、职业教育、智慧教育和技能培训</span>为一体的品牌教育集团。
                </div>
                <div class="companytext">
                    集团旗下有20多家实体园所，拥有高端的幼教科技研发团队，智慧化的管理平
                    台，以 <span> “ 实体园+ ”</span>的运营模式，坚持<span>产教融合、科教融汇</span>，引领托幼一体化发
                    展，赋能托幼产业升级，推动职教改革创新，实现了<span>教育链、人才链、产业链、
                        创新链</span>的有机衔接，致力打造有特色、高质量的教育集团。
                </div>
                <div class="companytext">
                    集团始终秉承着“用爱呵护成长”的教育理念，坚持“乐人达己.持续奉献”的
                    企业精神汇聚各领域专家和行业精英，携手高校、职业院校、行业龙头企业、生态伙伴，
                    厚植高质量发展基础，厚载特色化发展文化，厚积高端化发展实力，形成集团发展新生态。
                </div>
            </div>
            <div class="companyimgbox">
                <div class="companyimg"> <img src="../assets/index/1.png" alt="" srcset=""></div>
                <div class="compantbackg"></div>
            </div>
        </div>
        <div class="numbox">
            <div class="numone" v-for="(item,index) in numlist" :key="index">
                <div class="numtext">{{item.num}}</div>
                <div class="numtitle">{{item.title}}</div>

            </div>
        </div>
        <div class="development">
            <indexnav name="发展历程" color="#fff" capcolor="#fff" capslk="F" english="development history" />
            <div class="developmenttitle">这是我们成长的过程</div>
            <div class="developmentimg">
                <img src="../assets/index/2.png">
            </div>
            <div class="footerdev">
                BENCHMARK PENINSULA EDUCATION GROUP
            </div>
        </div>
        <div class="socialbox">
            <div class="socialbottom"></div>
            <indexnav name="社会职能" capslk="S" english="Social functions" />
            <div class="social">
                <el-carousel style="width:100%" :interval="4000" :height="flexshow?'280px':'380px'">
                    <el-carousel-item class="socialswiper">
                        <div class="socialone" v-for="(item,index) in socialimglist" :key="index">
                            <div class="socialimg">
                                <img :src="item.img" alt="" srcset="">
                            </div>
                            <div class="socialtext">
                                <div class="socialtext1">{{item.text}}</div>
                            </div>
                        </div>
                    </el-carousel-item>
                    <el-carousel-item class="socialswiper">
                        <div class="socialone" v-for="(item,index) in socialimglist1" :key="index">
                            <div class="socialimg">
                                <img :src="item.img" alt="" srcset="">
                            </div>
                            <div class="socialtext">
                                <div class="socialtext1">{{item.text}}</div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>

            </div>
            <!-- <indexnav name="师资团队" capslk="S" english="Teaching staff team" /> -->
            <!-- <div class="teacherbox">
                <el-carousel :interval="4000" type="card" :height="flexshow?'720px':'960px'">
                    <el-carousel-item v-for="(item,index) in carousellist" :key="index">
                        <div class="carouselimg">
                            <img :src="item.img" alt="" srcset="">
                        </div>
                        <div class="teachername">{{ item.name }}</div>
                        <div class="teacherfile">
                            <div class="teachersize" v-for="(itemt,indext) in item.size" :key="indext">{{ itemt }}</div>
                        </div>

                    </el-carousel-item>
                </el-carousel>
            </div> -->

        </div>
        <div class="enterprise">
            <indexnav name="企业优势" capslk="Q" english="Enterprise advantages" />
            <div class="enterpriseimg">
                <img src="../assets/index/3.png" alt="">
            </div>

        </div>
        <div class="email" v-show="emailshow">
            <div class="emailheader">
                <div class="ehimg">
                    <img src="../assets/index/4.png" alt="">
                </div>
                <div class="ehtext">填写留言</div>
                <i :class="ehcontentshow?'el-icon-caret-bottom emicon':'el-icon-caret-top emicon'" @click="ehcontentshow = !ehcontentshow"></i>
            </div>
            <div class="emailcontent" v-show="ehcontentshow">
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="姓名">
                        <el-input v-model="form.name" placeholder="请填写姓名"></el-input>
                    </el-form-item>
                    <el-form-item required label="手机号">
                        <el-input v-model="form.phoneNumber" placeholder="请填写手机号(必填)"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱">
                        <el-input v-model="form.email" placeholder="请填写邮箱"></el-input>
                    </el-form-item>
                    <el-form-item label="留言">
                        <el-input v-model="form.content" type="textarea" :rows="5" placeholder="您想告诉我们什么呢？"></el-input>
                    </el-form-item>
                    <div class="btton">
                        <el-button round @click="submit">确定发送</el-button>

                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import indexnav from "../components/indexnav.vue";
import alljson from "../store/alljson";
export default {
    components: {
        indexnav,
    },
    data() {
        return {
            ehcontentshow: true,
            flexshow: false,
            emailshow: false,
            form: {
                name: "",
                content: "",
                email: "",
                phoneNumber: "",
            },
            formset: {
                name: "",
                content: "",
                email: "",
                phoneNumber: "",
            },
            numlist: [
                {
                    num: "20+",
                    title: "二十余年行业沉淀引领行业标杆",
                },
                {
                    num: "1000+",
                    title: "累计服务教育培训机构数量",
                },
                {
                    num: "100000+",
                    title: "累计服务学生人数",
                },
                {
                    num: "20+",
                    title: "实体分校数量",
                },
                {
                    num: "1000+",
                    title: "师资队伍数量",
                },
                {
                    num: "20000+",
                    title: "在读学生人数",
                },
            ],
            socialimglist: [
                {
                    img: require("../assets/social/2.png"),
                    text: "中国人口学会托育服务分会理事单位",
                },
                {
                    img: require("../assets/social/1.png"),
                    text: "广东省人力资源研究会学前教育产学研 专业委员会副理事长单位",
                },
                {
                    img: require("../assets/social/3.png"),
                    text: "广东省家庭教育研究会婴幼儿照护与 早期发展专委会副会长单位",
                },
            ],
            socialimglist1: [
                {
                    img: require("../assets/social/4.png"),
                    text: "广东省早期教育行业协会副会长单位",
                },
                {
                    img: require("../assets/social/5.png"),
                    text: "华南师大教育发展联盟成员单位",
                },
                {
                    img: require("../assets/social/6.png"),
                    text: "《婴幼儿托育服务规范》团体标准起草单位",
                },
            ],
            carousellist: [],
        };
    },
    created() {
        var pageWidth =
            document.documentElement.clientWidth || document.body.clientWidth;
        if (pageWidth < 1920) {
            this.flexshow = true;
        } else {
            this.flexshow = false;
        }
        this.$bus.$on("changeemailshow", (data) => {
            this.emailshow = data;
        });
    },
    mounted() {
        this.carousellist = alljson.teacher;
    },
    beforeDestroy() {
        this.$bus.$off("changeemailshow");
    },
    methods: {
        submit() {
            if (this.form.phoneNumber == "") {
                this.$message({
                    showClose: true,
                    message: "手机号必填",
                    type: "error",
                });
                return;
            }
            var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
            if (!myreg.test(this.form.phoneNumber)) {
                this.$message({
                    message: "手机号错误",
                    type: "error",
                });
                return;
            }
            this.$api.email.createGuestbook(this.form).then((res) => {
                if (res.data.code == 100) {
                    this.$message({
                        showClose: true,
                        message: "留言成功",
                        type: "success",
                    });
                    this.form = Object.assign({}, this.formset);
                }else{
                    this.$message({
                        showClose: true,
                        message: res.data.desc,
                        type: "error",
                    });
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.indexbox {
    position: relative;
    .boximg {
        width: 100%;
    }
    .company {
        width: 74vw;
        margin: 20px auto;
        max-height: 450px;
        display: flex;
        justify-content: space-between;
        .companytext {
            line-height: 2;
            font-size: 20px;
            text-indent: 2em;
            height: auto;
            span {
                font-weight: 550;
            }
        }
        .companyimgbox {
            width: 45%;

            .companyimg {
                width: 90%;
                // height: 400px;
                margin: 0 auto;
                img {
                    width: 100%;
                }
            }
            .compantbackg {
                margin: 0 auto;
                width: 80%;
                height: 37px;
                background: rgba(190, 65, 63, 0.1);
                transform: translateY(-4px);
            }
        }
    }
    .companysmall {
        width: 74vw;
        margin: 20px auto;
        max-height: 340px;
        display: flex;
        justify-content: space-between;
        .companytext {
            line-height: 1.5;
            font-size: 16px;
            text-indent: 2em;
            height: auto;
            span {
                font-weight: 550;
            }
        }
        .companyimgbox {
            width: 45%;
            .companyimg {
                width: 90%;
                margin: 0 auto;
                img {
                    width: 100%;
                }
            }
            .compantbackg {
                margin: 0 auto;
                width: 80%;
                height: 37px;
                background: rgba(190, 65, 63, 0.1);
                transform: translateY(-4px);
            }
        }
    }
    .numbox {
        position: relative;
        z-index: 2;
        width: 55vw;
        background: #ffffff;
        box-shadow: 0px 6px 12px rgba(7, 44, 97, 0.5);
        opacity: 1;
        border-radius: 30px;
        padding: 40px 60px 20px;
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: space-between;
        margin: 0 auto;
        background-image: url("../assets/index/back.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        .numone {
            text-align: center;
            .numtext {
                font-size: 50px;
                font-weight: bold;
                color: #be413f;
            }
            .numtitle {
                font-size: 20px;
                font-family: Source Han Sans CN;
                margin: 20px 0;
            }
        }
    }
    .development {
        width: 100%;
        background-image: url("../assets/index/back1.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        padding: 100px 0 20px;
        transform: translateY(-40px);
        .developmenttitle {
            text-align: center;
            color: #fff;
            font-size: 20px;
        }
        .developmentimg {
            margin: 20px auto 80px;
            width: 70%;

            img {
                width: 100%;
            }
        }
        .footerdev {
            text-align: center;
            font-size: 14px;
            // line-height: 32px;
            color: rgba(255, 255, 255, 0.3);
            letter-spacing: 28px;
        }
    }
    .socialbox {
        position: relative;
        width: 100%;
        // height: 1400px;
        padding: 30px 0;
        background-image: url("../assets/index/back2.png");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: top;
        .socialbottom {
            position: absolute;
            bottom: 0;
            height: 500px;
            background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0.06) 0%,
                rgba(58, 135, 239, 0.1) 100%
            );
            opacity: 1;
            width: 100%;
        }
        .social {
            width: 70%;
            padding: 50px 30px 0px;
            background: #ffffff;
            box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.16);
            border-radius: 20px;

            margin: 90px auto 30px;
            .socialswiper {
                width: 100%;
                display: flex;
                justify-content: space-between;
                .socialone {
                    width: 30%;
                    .socialimg {
                        width: 100%;
                        img {
                            width: 100%;
                        }
                    }
                    .socialtext {
                        margin-top: 4px;
                        display: flex;
                        justify-content: center;
                        text-align: center;
                        padding: 10px 20px;
                        border: 1px solid rgba(153, 153, 153, 0.3686);
                        border-radius: 8px;
                        height: 42px;
                    }
                    .socialtext1 {
                        align-self: center;
                    }
                }
            }
        }
        .teacherbox {
            width: 70%;
            margin: 0 auto;
            .carouselimg {
                width: 100%;
                // height: 450px;
                img {
                    // height: 450px;
                    width: 100%;
                }
            }
            .teachername {
                padding: 20px 0;
                color: #fff;
                text-align: center;
                font-size: 26px;
                background: #084190;
                border-radius: 6px 6px 0px 0px;
                transform: translateY(-5px);
            }
            .teacherfile {
                background: #fff;
                padding: 20px 10px;
                transform: translateY(-5px);
                // overflow-y: auto;
                // height: 180px;
                .teachersize {
                    font-size: 22px;
                    font-family: Microsoft YaHei;
                    color: #000000;
                }
            }
        }
    }
    .enterprise {
        background: rgba(190, 65, 63, 0.06);
        width: 100%;
        padding: 30px 0;
        .enterpriseimg {
            width: 70%;
            margin: 30px auto 0;
            img {
                width: 100%;
            }
        }
    }
    .email {
        width: 420px;
        position: fixed;
        bottom: 0;
        right: 0;
        .emailheader {
            background: #034198;
            border-radius: 20px 20px 0px 0px;
            display: flex;
            padding: 10px 20px;
            align-items: center;
            font-size: 16px;
            color: #ffffff;
            .ehimg {
                width: 46px;
                height: 46px;
                margin-right: 20px;
                img {
                    width: 100%;
                }
            }
            .ehtext {
                width: 300px;
            }
            .emicon {
                font-size: 30px;
            }
        }
        .emailcontent {
            background: #f9f9f9;
            border-radius: 10px;
            padding: 30px;
            /deep/.el-input__inner {
                border-radius: 20px;
            }
            .btton {
                width: 100%;
                margin-top: 20px;
                /deep/.el-button {
                    width: 100%;
                    border: none;
                    background: #034198;
                    color: #fff;
                }
            }
        }
    }
}
</style>