<template>
    <div class="typesmall">
        <div class="captext"  :style="'color:'+capcolor">{{capslk}}</div>
        <div class="engbox">
            <div class="engtext" :style="'color:'+color">{{english}}</div>
            <div class="engname"  :style="'color:'+capcolor">{{name}}</div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        name: String,
        capslk: String,
        english: String,
        color:{
            type: String,
            default: "#000"
        },
        capcolor:{
            type: String,
            default: "#be413f"
        },

    },
};
</script>
<style lang="scss" scoped>
.typesmall {
    display: flex;
    justify-content: center;
    .captext {
        font-size: 60px;
        font-weight: bold;
        margin-right: 10px;
    }
    .engbox {
        align-self: center;
        .engtext {
            font-size: 14px;
            font-family: Microsoft YaHei;
        }
        .engname {
            font-size: 30px;
            font-weight: bold;
        }
    }
}
</style>